import React, { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-7 w-7 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fbbd69"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}


const CommonQuestion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <section className="tv-section8">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Some common questions</h2>
                    <div className="max-width1000">
                        <Fragment>
                            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(4)}>
                                What is Purple Cow's Neighbor in Need program?                               </AccordionHeader>
                                <AccordionBody>
                                    We understand that life can throw challenges your way, and at Purple Cow, we believe in lifting up our herd. That’s why we’re rolling out the Neighbor in Need program, where our community can come together to make a real difference. We’re inviting members to donate just $2 a month, and Purple Cow will match each contribution, dollar for dollar.
                                    <br></br><br></br> These funds will go straight to helping our neighbors stay connected when times are tough, keeping their internet running so they can find their footing and get back on track. Because at Purple Cow, we’re more than just an internet provider—we’re a family that’s here to support each other, no matter what.
                                    <br></br><br></br>To launch the Neighbor in Need program, Purple Cow is proud to take the first step by contributing an initial $10,000 to the fund. This kickstart ensures that we can immediately begin supporting our neighbors who need help staying connected. We’re excited to see the herd rally together and grow this fund, so we can help even more of our community get back on their hooves. Together, we’re making a difference—let’s go!
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(1)}>
                                     Supporting the Neighbor in Need program is simple!
                                </AccordionHeader>
                                <AccordionBody>
                                Just add $2 to your monthly renewal right through your account, and your contribution will go directly into a fund to help keep our neighbors connected. If you’d prefer to make a one-time donation, just send us a message or give us a call at 1-902-800-2660. Every little bit helps, and when the herd comes together, we make a big impact. Let’s keep our community strong, connected, and moving forward—together! </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(2)}>
                                How are the Neighbor in Need funds used?
                                </AccordionHeader>
                                <AccordionBody>
                                Every dollar raised through the Neighbor in Need program goes directly to covering internet bills for customers facing tough times, as well as supporting vulnerable groups across the Maritimes—including low-income parents, the elderly, and the homeless. We know it’s not always easy out there, and our goal is to lend a helping hand to keep everyone connected.
                                <br></br>To ensure the program stays fair and sustainable, we’ve put measures in place to prevent misuse, so the funds reach those who truly need support. By coming together as a herd, we can make sure our community continues to thrive, one connection at a time.
                               
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(3)}>
                                Why we started the Neighbor in Need program
                                </AccordionHeader>
                                    <AccordionBody>
                                        Lately, we’ve been hearing from the herd about job losses, unexpected expenses, and other financial hurdles that make it tough to stay on top of bills. At Purple Cow, we’re grateful for the strong community support we’ve received, and we believe in giving back when our neighbors are in need. That’s why we created the Neighbor in Need program—because when the herd helps the herd, no one gets left behind. We’re here to ensure that our community stays connected, even in tough times. Together, we can make a real difference.                        
                                    </AccordionBody>
                                </Accordion>
                            
                        </Fragment>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CommonQuestion
